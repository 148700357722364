/** @format */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { Business, CreditCard, Person, Room } from '@mui/icons-material';

import { ControlledAutocomplete, ControlledTextField } from 'components';
import { states } from 'mocks';

const CreditCardForm = ({ ccTypes, control, errors, setValue }) => {
  const [formData, setFormData] = useState({ creditCardNumber: '' });

  useEffect(() => {
    if (formData.creditCardNumber.length > 0) {
      switch (formData.creditCardNumber[0]) {
        case '3': // Amex
          setValue('cardType', ccTypes[0]);
          break;
        case '4': // Visa
          setValue('cardType', ccTypes[3]);
          break;
        case '5': // Mc
          setValue('cardType', ccTypes[2]);
          break;
        case '6': // Disc
          setValue('cardType', ccTypes[1]);
          break;
        default:
      }
    }
  }, [ccTypes, setValue, formData.creditCardNumber]);

  return (
    <Grid container item spacing={3}>
      <Grid item xs={12} ss={6}>
        <ControlledTextField
          control={control}
          error={!!errors.firstName}
          errorMessage={errors.firstName?.message}
          label='First Name'
          name='firstName'
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <Person />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} ss={6}>
        <ControlledTextField
          control={control}
          error={!!errors.lastName}
          errorMessage={errors.lastName?.message}
          label='Last Name'
          name='lastName'
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <Person />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} ss={6}>
        <ControlledTextField
          control={control}
          error={!!errors.creditCardNumber}
          errorMessage={errors.creditCardNumber?.message}
          label='Card Number'
          name='creditCardNumber'
          type='number'
          setFormData={setFormData}
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <CreditCard />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} ss={6}>
        <ControlledTextField
          control={control}
          error={!!errors.expirationDate}
          errorMessage={errors.expirationDate?.message}
          label='Expiration Date'
          name='expirationDate'
          placeholder='MM/YY (e.g. 12/27 if expires in Dec 2027)'
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid item xs={12} ss={6}>
        <ControlledTextField
          control={control}
          error={!!errors.ccv}
          errorMessage={errors.ccv?.message}
          label='CCV'
          name='ccv'
          type='number'
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <CreditCard />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} ss={5.9}>
        <ControlledAutocomplete
          control={control}
          defaultValue={null}
          error={!!errors.cardType}
          errorMessage={errors.cardType?.message}
          label='Card Type'
          name='cardType'
          options={ccTypes}
          getOptionLabel={(option) => option.description || ''}
          renderOption={(props, option) => {
            const CardIcon = () => (
              <img loading='lazy' width='24' src={`/${option.code}_card.svg`} alt={`${option.code} icon`} />
            );
            return (
              <Stack {...props} direction='row' spacing={1} key={option.id}>
                <CardIcon />
                <Typography>{option.description || ''}</Typography>
              </Stack>
            );
          }}
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <CreditCard />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} ss={6}>
        <ControlledTextField
          control={control}
          error={!!errors.address}
          errorMessage={errors.address?.message}
          label='Address'
          name='address'
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <Room />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} ss={6}>
        <ControlledTextField
          control={control}
          error={!!errors.city}
          errorMessage={errors.city?.message}
          label='City'
          name='city'
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <Room />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} ss={6}>
        <ControlledTextField
          control={control}
          error={!!errors.zip}
          errorMessage={errors.zip?.message}
          label='Zip'
          name='zip'
          type='number'
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <Room />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} ss={5.9}>
        <ControlledAutocomplete
          control={control}
          defaultValue={null}
          error={!!errors.state}
          errorMessage={errors.state?.message}
          getOptionLabel={(option) => option.name || ''}
          label='State'
          name='state'
          options={states}
          InputProps={{
            style: { fontSize: '16px' },
            startAdornment: (
              <InputAdornment position='start'>
                <Business />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

CreditCardForm.propTypes = {
  ccTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.shape({ message: PropTypes.string }),
    lastName: PropTypes.shape({ message: PropTypes.string }),
    creditCardNumber: PropTypes.shape({ message: PropTypes.string }),
    cardType: PropTypes.shape({ message: PropTypes.string }),
    ccv: PropTypes.shape({ message: PropTypes.string }),
    expirationDate: PropTypes.shape({ message: PropTypes.string }),
    address: PropTypes.shape({ message: PropTypes.string }),
    city: PropTypes.shape({ message: PropTypes.string }),
    zip: PropTypes.shape({ message: PropTypes.string }),
    state: PropTypes.shape({ message: PropTypes.string }),
  }).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default CreditCardForm;
