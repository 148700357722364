/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle, Link } from '@mui/material';
import platform from 'platform';

import GlobalStyle from './GlobalStyle';
import useStyles from './styles';

const Presentation = ({ children }) => {
  const classes = useStyles();
  const isChrome = platform?.name?.toLowerCase().indexOf('chrome') !== -1;
  const isEdge = platform?.name?.toLowerCase().indexOf('edge') !== -1;

  return (
    <section className={classes.root}>
      <GlobalStyle />
      {!(isChrome || isEdge) && (
        <Alert className={classes.alert} severity='error'>
          <AlertTitle>Unsupported Browser</AlertTitle>
          Your current browser is <strong>{platform.name}</strong>, which is not supported. Please use{' '}
          <Link href='https://www.google.com/chrome/downloads/' target='_new' underline='none'>
            Google Chrome
          </Link>{' '}
          or{' '}
          <Link href='https://www.microsoft.com/en-us/edge' target='_new' underline='none'>
            Microsoft Edge
          </Link>{' '}
          for optimal experience.
        </Alert>
      )}
      <section className={classes.content}>{children}</section>
    </section>
  );
};

Presentation.propTypes = { children: PropTypes.node };
Presentation.defaultProps = { children: null };

export default Presentation;
